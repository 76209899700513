/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-sider-menu-logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
}
.ant-pro-sider-menu-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.ant-pro-sider-menu-logo h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
.ant-pro-sider-menu-sider {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}
.ant-pro-sider-menu-sider.fix-sider-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-root {
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-inline {
  border-right: 0;
}
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-inline .ant-menu-item,
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-pro-sider-menu-sider.light {
  background-color: white;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.ant-pro-sider-menu-sider.light .ant-pro-sider-menu-logo {
  background: #3677EA !important;
  /* box-shadow: 1px 1px 0 0 #e8e8e8; */
}
.ant-pro-sider-menu-sider.light .ant-pro-sider-menu-logo h1 {
  color: #63A7E0;
}
.ant-pro-sider-menu-sider.light .ant-menu-light {
  border-right-color: transparent;
}
.ant-pro-sider-menu-icon {
  width: 14px;
  vertical-align: baseline;
}
.ant-pro-sider-menu .top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
.ant-pro-sider-menu .drawer .drawer-content {
  background: #001529;
}
.ant-pro-sider-menu .ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-pro-sider-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-pro-sider-menu .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-pro-sider-menu .ant-menu-item .sider-menu-item-img + span,
.ant-pro-sider-menu .ant-menu-submenu-title .sider-menu-item-img + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-pro-sider-menu .ant-drawer-body {
  padding: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-global-header {
  position: relative;
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.ant-pro-global-header-logo {
  display: inline-block;
  height: 64px;
  padding: 0 0 0 24px;
  font-size: 20px;
  line-height: 64px;
  vertical-align: top;
  cursor: pointer;
}
.ant-pro-global-header-logo img {
  display: inline-block;
  width: 32px;
  vertical-align: middle;
}
.ant-pro-global-header-menu .anticon {
  margin-right: 8px;
}
.ant-pro-global-header-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.ant-pro-global-header-trigger {
  height: 64px;
  padding: calc((64px - 26px) / 2) 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.ant-pro-global-header-trigger:hover {
  background: #fff;
}
.ant-pro-global-header .dark {
  height: 64px;
}
.ant-pro-global-header .dark .action {
  color: rgba(255, 255, 255, 0.85);
}
.ant-pro-global-header .dark .action > i {
  color: rgba(255, 255, 255, 0.85);
}
.ant-pro-global-header .dark .action:hover,
.ant-pro-global-header .dark .action.opened {
  background: #63A7E0;
}
.ant-pro-global-header .dark .action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
